import React, { useState, useEffect, useCallback } from 'react';
import { Steps } from 'intro.js-react';
import { NavLi, Img } from './Styles';
import guideIcon from '../Assets/Icons/Help.svg';
import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css';
import drimsGuide from '../Assets/drimsGuide.json';

// props: location 
export default function Guide(props) {
  const [guideState, setGuideState] = useState(false);
  // grabs the first word with first forward slash of the location
  const currentPage = props.location.match(/^\/\w*/g)[0];
  const flightId = localStorage.getItem('flightId') || null;
  const [stepHook, setStepHook] = useState(null);

  // depending on the location returns appropriate steps
  const getSteps = () => {

    return drimsGuide[currentPage];
    /*
    */
  }

  // before change method for introjs, triggers specific events according to
  // the active page and step.
  const beforeClick = (nextStepIndex) => {
    if (currentPage === '/project') { 
      // force user to select the flight to see the change
      if (nextStepIndex === 3 && !localStorage.getItem('flightId')) {
        return false;
      }

      const mapControls = document.querySelector('.mapControls');
      if (nextStepIndex === 6 && mapControls) {
        const marker = document.querySelector('.mapMarker');
        if (marker) {
          marker.click();
          mapControls.children[0].click();
        }
      }
      if (nextStepIndex === 8 && mapControls) {
        mapControls.children[1].click();
      }
      if (nextStepIndex === 9 && mapControls) {
        mapControls.children[2].click();
      }
    } else if (currentPage === '/analysis') { 
      // force user to select the flight to see the change
      if (nextStepIndex === 2 && !localStorage.getItem('flightId')) {
        return false;
      }
      
      const marker = document.querySelector('.mapMarker');
      if (nextStepIndex === 4 && marker) {
        marker.click();
      }
    } else if (currentPage === '/alerts') {
      const alerts = document.querySelector('.alertsMenu').children;
      if (nextStepIndex === 3 && alerts.length) {
        alerts[0].click(); 
      }
    }
  }

  const onExit = () => {
    setGuideState(false);
  }

  //options={{disableInteraction: true}}
  return(
    <NavLi onClick={() => setGuideState(true)} title='Guide'> 
      <Img 
        src={guideIcon} 
        width='1.7rem' 
        alt='navIcon/guide'
      />
      <Steps
        style={{fontFamily: 'Montserrat !important'}}
        ref={step => setStepHook(step)}
        enabled={guideState}
        steps={getSteps()}
        onBeforeChange={beforeClick}
        initialStep={0}
        onExit={onExit}
      />
    </NavLi>
  ); 
}

