import React from 'react';
import { Gauge } from './Styles';
import avgIcon from '../Assets/Icons/AVG_Callout.svg';

// props: cluster, name, min, max, unit, chartWidth
export default function Sensor({
  cluster, max, min, val, unit, name, chartWidth
}) {

  // function to calcualte hue value of the gauge filler
  const percentToHue = (percent) => {
    const hue = (0 - 120) * (percent / 100) + 120;

    return `hsl(${hue},100%, 40%)`;
  }

  // 1. calculate the percent of the "props.val" between "props.min" and
  //    "props.max".
  const percent = ((val - min) * 100) / (max - min);

  // 2. Divide turn by 200 which to get proper decimal for % of semicircle
  // 3. Calculate the hsl string using hue with calculated "percent"
  const turn = percent/200;
  const fill = percentToHue(percent);

  return (
    <div>
      {cluster ? <img src={avgIcon} alt='clusterIcon'/> : null}
      <div>
        <span>{name}</span>
        <br/>
        <span>{min ? `MIN: ${min.toFixed(3)}` : null }</span>
        <span>{max ? `MAX: ${max.toFixed(3)}` : null }</span>
      </div>
      <div>
        { cluster ? 
          <Gauge 
            width={'10rem'}
            turn={turn}
            fill={fill}
          >
            <span>{unit}</span>
            <div>
              <div></div>
              <div><span>{val?.toFixed(3)}</span></div>
            </div>
          </Gauge>
        :
          <>
            <span style={{fontSize: '2rem'}}>{val ? val.toFixed(3) : '-'}</span>
            <span style={{color: 'grey'}}>{unit}</span>
          </>
        }
      </div>
    </div>
  );
}
