import React, { useState } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './Containers/PrivateRoute'
import Home from './Pages/Home';
import Navbar from './Containers/Navbar';
import Project from './Pages/Project';
import Analysis from './Pages/Analysis';
import Alerts from './Pages/Alerts';
import Login from './Pages/Login';
import NotFound from './Pages/NotFound';
import { AuthContext } from './Containers/Context/auth';
import { Fonts, Main } from './Containers/Styles';
import { doFetch } from './Containers/calls';
import { SWRConfig } from 'swr';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'f600b9b7-f290-4293-baed-0cd55e94b01f',
    clientToken: 'pubd27b7a7eae09ee2ef80ca67d9f1ff7f8',
    site: 'datadoghq.com',
    service:'drims2',

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel:'mask-user-input'
});

datadogRum.startSessionReplayRecording();

function App() {
  const userToken = localStorage.getItem('token') || null;
  const [authToken, setAuthToken] = useState(userToken);

  const setTokens = (data) => {
    localStorage.setItem('token', data);
    setAuthToken(data);
  }

  const resetTokens = () => {
    doFetch('GET', '/auth/logout')
      .then(json => {
        console.log('logout ok', json)
        localStorage.clear();
        setAuthToken(null);
      })
      .catch(err => console.log('logout had err: ', err));
  }

  // fetcher for global SWRConfig with error handling
  /*
  const fetcher = (...args) => {
    // add authorization 
    if (localStorage.token) {
      myHeaders['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(...args, 
      { 
        credentials: 'include', 
        headers: myHeaders,
      }
    ).then(res => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        resetTokens();
      }
      throw new Error([res.status, res.statusText]);
    })
  }
    <SWRConfig value={{fetcher}}>
    </SWRConfig>
  */

  return (
    <Main>
      <Fonts/>
      <AuthContext.Provider value={
        {authToken, setAuthToken: setTokens, resetTokens: resetTokens}
      }>
        <Router>
          { 
            authToken ? 
            <Navbar
              active='/'
              logOut={resetTokens}
              /> 
            :<div/> 
          }
          <Switch>
            <PrivateRoute path='/project/:project' component={Project}/>
            <PrivateRoute path='/analysis/:project' component={Analysis}/>
            <PrivateRoute path='/alerts' component={Alerts}/>
            <PrivateRoute exact path='/' component={Home}/>
            <Route path='/login' component={Login} />
            <Route component={NotFound}/>
          </Switch> 
        </Router>
      </AuthContext.Provider>
    </Main>
  );
}

export default App;

