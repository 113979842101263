import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './Context/auth';

// Redirects to login, if not authenticated
export default function PrivateRoute({ component: Component, ...rest }) {
  const { authToken } = useAuth();

  return (
    <Route {...rest} component={(props) => (
      authToken ? (
        <Component {...props} {...rest}/>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    )}  />
  );
}
