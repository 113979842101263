import React, { useState } from 'react';
import syncIcon from '../Assets/Icons/InnerBox_Sync.svg';
import { dateParser } from '../Containers/utility';
import { Page, AlertsWrap, AlertsMenu, Button, SecondaryButton,
  RowCheckbox, Ul, LastSync, AlertWindow, AlertHeader, GridLi, Circle
} from '../Containers/Styles';
import { doFetch } from '../Containers/calls';

export default function Alerts(props) {
  console.log('Alerts rendered');
  const [alerts, setAlerts] = useState({1598102170109:{"header":"headerText0","body":"bodyText0","read":0},1598127446160:{"header":"headerText1","body":"bodyText1","read":1},1597786892009:{"header":"headerText2","body":"bodyText2","read":0},1597777773945:{"header":"headerText3","body":"bodyText3","read":1}});
  const [unreadFilter, setUnreadFilter] = useState(0);
  const [activeAlert, setActiveAlert] = useState(0);

  /* 
  // *** DEV Methods ***
  const fakeAlerts = (num) => {
    const makeAlerts = (num) => {
      let obj = {};
      for (let i = 0; i < num; ++i) {
        const date = randomDate(
          new Date(2020,5,10), new Date(2020,6,1)
        );
        alerts[date] = {
          header: `headerText${i}`, 
          body: `bodyText${i}`, 
          read: i%2 
        }
      }
    }
    makeAlerts(num);
    console.log(JSON.stringify(alerts));
  }

  //format of an alert
  const als = {
    [+new Date()]: {
      header: 'header text',
      body: 'body text',
      read: 0
    }
  };

  fakeAlerts(4);

  // returns random date in epoch
  function randomDate(start, end) {
    return +new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  } 
  // *** EO DEV Methods. ***
  */

  const liClickHandle = (key) => {
    alerts[key].read = 1;
    setActiveAlert({...alerts[key], date: +key});
  }

  doFetch('GET', '/auth/me', 0)
    .then(json => {
      console.log(json);
    })
    .catch(err => console.log(err));

  return(
    <Page bgImg={1}>
      <AlertsWrap>
        <AlertsMenu>
          <SecondaryButton className='syncButton' icon={syncIcon}>
            <span>Sync Notifications</span>
          </SecondaryButton>
          <LastSync>Last Sync Completed {'"date"'}</LastSync>
          <RowCheckbox htmlFor='readId'>
            <span>Show Unread Only</span> 
            <input
              id='readId'
              type='checkbox'
              onChange={e => setUnreadFilter(!unreadFilter) }/>
          </RowCheckbox>
          <Ul gap='.1rem' className='alertsMenu'>
            {Object.keys(alerts).filter((key) =>
              !unreadFilter || (!alerts[key].read && unreadFilter)
            ).map((key, i) => {
              return (
                <GridLi 
                  key={'alertLi'+i}
                  height='4rem'
                  selected={+key === activeAlert.date}
                  onClick={(e) => liClickHandle(key)}>
                  <span>{alerts[key].header}</span>
                  <span>{dateParser(+key, 1)}</span>
                  <span>{alerts[key].body}</span>
                  <Circle style={ 
                    !alerts[key].read ? 
                    {background: 'orange'} : 
                    null
                  }/>
                </GridLi>
              );
            })}
          </Ul>
          <Button 
            height='2.5rem' 
            justify='flex-end'
            marginRight='.2rem'>MARK ALL READ</Button>
        </AlertsMenu>
        <AlertWindow 
          className='alertWindow'
          hide={ activeAlert ? 0 : 1 }
          onClick={e => setActiveAlert(0)}>
          <AlertHeader>
            <span>{activeAlert.header}</span>
            <span>{dateParser(activeAlert.date, 1)}</span>
          </AlertHeader>
          <div>{activeAlert.body}</div>
        </AlertWindow>
      </AlertsWrap>
    </Page>
  );
}
