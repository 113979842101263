import React, { useState, useRef, useCallback, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MarkerButton, colors, MaxIntensController, Input, Button } from './Styles';
import defaultMapIcon from '../Assets/Icons/Map_main.svg';
import map3dIcon from '../Assets/Icons/3DMapping.svg';
import heatmapIcon from '../Assets/Icons/Heatmap.svg';
import pathIcon from '../Assets/Icons/pathIcon.svg';
import useSupercluster from 'use-supercluster';
import '../index.css';

// props: flight, setNodeInfo, mapControls, selectedCoords
export default function GoogleMap(props) {
  const mapRef = useRef();
  const mapsRef = useRef();
  const [zoom, setZoom] = useState(3);
  const [bounds, setBounds] = useState({
    ne: { lat: 63, lng: 180 },
    nw: { lat: 63, lng: -146.25 },
    se: { lat: -63, lng: 180 },
    sw: { lat: -63, lng: -146.25 }
  });
  const [mapType, setMapType] = useState('satellite');
  // active control is made to decide what control is on. 0 - default,
  // 1 - heatmap, 2 - flightpath, 3 - 3d map
  const [activeControl, setActiveControl] = useState(0);
  const [flightPath, setFlightPath] = useState(null);
  const [mapPoints, setPoints] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);
  const [heatmapInstance, setHeatmapInstance] = useState('');
  const [customMax, setCustomMax] = useState(0);
  const [heatmapSensor, setHeatmapSensor] = useState({
    id: null,
    limit: null
  });
  const validationSchema = Yup.object().shape({
    max: Yup.number()
      .required('The number is required!')
      .min(0)
      .typeError('value must be number')
    // .oneOf(['Yes'], 'Unable to delete before confirmation'),
  });
  const formOptions = {
    mode: 'all',
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, reset, formState: { errors } } = useForm(formOptions);
  const onSubmit = (data) => {
    if (data.max >= 0) {
      setCustomMax(data.max);
      heatmapInstance.setOptions({
        data: heatmapData,
        maxIntensity: data.max
      })
    } else {
      alert('Maximum value should not be negative')
    }
  }
  // ** EFFECT METHODS **
  // 1. Updates map whenever flight changes
  useEffect(() => {
    /*remove previous heatmap instance when changing flight 
    since map will create a new heatmap instance
    */
    if (heatmapInstance) {
      heatmapInstance.setMap(null);
      setHeatmapInstance('');
      reset();
    }
    if (props.flight.samples && props.flight.samples.length) {
      // 1.1 Build points for marker/cluster format, and add alt to values for
      // avg calculation
      const points = props.flight?.samples.map(node => ({
        type: 'Feature',
        properties: {
          cluster: false,
          nodeId: node.timestamp,
          value: {
            ...node.values, alt: {
              value: node.altitude,
              sensor_name: 'Altitude'
            }
          }
        },
        geometry: { type: 'Point', coordinates: [node.longitude, node.latitude] }
      }));

      // 1.2 set points for clusters
      setPoints(points);

      // 1.3 When points are built - ...
      if (mapRef.current && points.length) {
        // 1.3.1 reset the control to default map view
        setActiveControl(0);

        // 1.3.2 fit bounds to a new flight 
        const initPoint = {
          lat: points[0].geometry.coordinates[1],
          lng: points[0].geometry.coordinates[0]
        };
        const flightBounds = new mapsRef.current.LatLngBounds();

        for (let i in points) {
          flightBounds.extend({
            lat: points[i].geometry.coordinates[1],
            lng: points[i].geometry.coordinates[0]
          });
        }
        mapRef.current.fitBounds(flightBounds);

        // 1.3.3 create flightpath with polylines 
        const path = points.map(p => ({
          'lat': p.geometry.coordinates[1],
          'lng': p.geometry.coordinates[0]
        }));

        // 1.3.4 Reset previous flightpath if it was already created
        if (flightPath) {
          flightPath.setMap(null);
        }
        // 1.3.5 Set new flight path
        setFlightPath(new mapsRef.current.Polyline({
          path: path,
          geodesic: 1,
          strokeColor: '#ffc403',
          strokeOpacity: 1,
          strokeWeight: 2
        }));
      }

      // 1.3.6 Set heatmap sensor, if no aq_limit is set, set it yourself
      let aq_limit = props.flight.sensors[0].aq_limit;
      if (!aq_limit) {
        aq_limit = Math.max.apply(Math,
          props.flight.samples.map(function (o) {
            return o.values[props.flight.sensors[0].id].value;
          }
          ));
      }

      // 1.3.7 add heatmap sensors for heatmap dropdown 
      if (props.mapControls && props.flight.samples.length &&
        mapRef.current) {
        const controlsWrap = mapRef.current.controls
        [mapsRef.current.ControlPosition.TOP_CENTER];
        let controls = null;
        for (let i in controlsWrap) {
          if (controlsWrap[i] &&
            controlsWrap[i][0] &&
            controlsWrap[i][0].className === 'mapControls') {
            controls = controlsWrap[i][0];
            break;
          }
        }

        // clear prev dropdown
        if (controls.children.length > 3) {
          controls.removeChild(controls.children[3]);
        }

        // reset heatmapSensor
        setHeatmapSensor({
          id: null,
          limit: null
        });

        // create new one
        const dropdown = document.createElement('select');
        dropdown.style.backgroundColor = colors.mainBlack;
        dropdown.style.gridColumn = '1/4';
        dropdown.style.color = '#fff';
        dropdown.style.fontSize = '1rem';
        dropdown.style.border = 0;
        dropdown.style.height = '0';
        dropdown.style.cursor = 'pointer';
        dropdown.style.transition = 'height .5s ease';
        dropdown.classList.add('heatDropdown');

        // add placeholder
        const option = document.createElement('option');
        option.innerText = 'Select sensor';
        option.value = '';
        option.disabled = 1;
        option.hidden = 1;
        option.selected = 1;
        dropdown.appendChild(option);

        // add event
        dropdown.addEventListener('change', function (e) {
          for (let i in props.flight.sensors) {
            if (e.target.value === props.flight.sensors[i].id) {
              setHeatmapSensor({
                id: e.target.value,
                limit: props.flight.sensors[i].aq_limit * 1.2
              });
              break;
            }
          }
        });

        // fill up dropdown
        for (let i in props.flight.sensors) {
          const sensor = props.flight.sensors[i];
          const option = document.createElement('option');
          option.innerText = sensor.name;
          option.value = sensor.id;
          dropdown.appendChild(option);
        }

        controls.appendChild(dropdown);
      }
      // create heatmap layer without data when flight is chosen. Update data when sensor is selected after
      if (mapsRef.current) {
        const heatmapInst = new mapsRef.current.visualization.HeatmapLayer({
          data: [],
          dissipating: 0,
          radius: 0.00004,
          opacity: 1,
          // maxIntensity: heatmapSensor?.limit
          maxIntensity: 0
        });
        setHeatmapInstance(heatmapInst);
      }
    }
  }, [props.flight]);

  // remove current heatmap when switch with mapControls
  useEffect(() => {
    if (heatmapInstance !== '')
      heatmapInstance.setOptions({
        data: [],
      })
    reset();
  }, [activeControl]);

  // 2. update heatmap when heatmapsensor is updated 
  useEffect(() => {
    console.log('heatmap effect render');
    reset();
    if (props.flight.samples && props.flight.samples.length &&
      props.mapControls) {
      // 2. ** Build heatmap data and calculate saturation and weight **
      let initHeatPositions = props.flight.samples.map(node => ({
        lat: node.latitude,
        lng: node.longitude,
        weight: heatmapSensor.id ? node.values[heatmapSensor.id].value : 0
      }));
      // 2.1 2d loop creates a 2d array with all the indexes of all points 
      // that have nested array that provides indexes of points that are 
      // located close to the parent.
      // const step = 0.000009;
      const step = 0.00005;
      let uniquePositions = [];
      for (let i = 0; i < initHeatPositions.length; ++i) {
        var closePoints = [];
        for (let j = i + 1; j < initHeatPositions.length; ++j) {
          const lngDiff = initHeatPositions[i].lng - initHeatPositions[j].lng;
          const latDiff = initHeatPositions[j].lat - initHeatPositions[i].lat;
          const distance = Math.sqrt(Math.pow(lngDiff, 2) + Math.pow(latDiff, 2));
          if (distance < step) {
            closePoints.push(j);
          }
        }
        uniquePositions.push(closePoints);
      }
      // 2.2 Then 2d array have to be filtered for unique points, to remove 
      // repetitions of nested children of close located points.
      for (let i = 0; i < uniquePositions.length; ++i) {
        const pos = uniquePositions[i];
        // if position has not been deleted due nesting 
        if (pos) {
          for (let j = 0; j < pos.length; ++j) {
            const unique = pos[j];

            // Delete repeating position either in nested children or in its own
            // index
            if (uniquePositions[unique]) {
              uniquePositions[unique] = null;
            } else {
              pos.splice([pos.indexOf(unique)], 1);
              --j;
            }
          }
        }
      }
      // 2.3 Finally it builds an array of heatPoints using unique indexes for
      // previous step
      const heatPositions = [];
      for (let i = 0; i < uniquePositions.length; ++i) {
        // if current unique position is not 'null'
        if (uniquePositions[i]) {
          // if it's a cluster
          if (uniquePositions[i].length) {
            const pos = {
              lat: initHeatPositions[i].lat,
              lng: initHeatPositions[i].lng,
              weight: initHeatPositions[i].weight
            };
            for (let j in uniquePositions[i]) {
              pos.lat += initHeatPositions[uniquePositions[i][j]].lat;
              pos.lng += initHeatPositions[uniquePositions[i][j]].lng;
              pos.weight += initHeatPositions[uniquePositions[i][j]].weight;
            }
            pos.lat /= (uniquePositions[i].length + 1);
            pos.lng /= (uniquePositions[i].length + 1);
            pos.weight /= (uniquePositions[i].length + 1);

            const heatmapPoint = {
              location: new mapsRef.current.LatLng(pos.lat, pos.lng),
              weight: pos.weight,
            }

            // heatPositions.push(pos);
            heatPositions.push(heatmapPoint);
          } else { // else it's 1 point
            const heatmapPoint = {
              location: new mapsRef.current.LatLng(initHeatPositions[i].lat, initHeatPositions[i].lng),
              weight: initHeatPositions[i].weight,
            }
            // heatPositions.push(initHeatPositions[i]);
            heatPositions.push(heatmapPoint);
          }
        }
      }
      // clear temporary data used for calculation
      initHeatPositions = null;
      uniquePositions = null;
      setHeatmapData(heatPositions);
      // Once new sensor selected, heatmap will not show until max value and "plot" btn clicked
      activeControl === 1 && heatmapInstance.setMap(mapRef.current);
      heatmapInstance.setOptions({
        data: [],
      })

      // TBD: testing method for heatmap unique point consistency
      /*
      let last = uniquePositions.length-1;
      for (let i = 0; i < uniquePositions.length; ++i) {
        const pos = uniquePositions[i];
        if (pos) {
          for (let j = 0; j < pos.length; ++j) {
            if (pos[j] === last) {
              console.log(i + ' is unique cluster with last point');
              last = null;
            }
          }
        }
      }
      if (last) {
        console.log('last point is unique, no issues');
      }
      */
    }
  }, [heatmapSensor]);

  // update map according to its control
  useEffect(() => {
    if (mapRef.current && flightPath) {
      // reset map values (remove flight path, reset tilt and set all map
      // controls to black color);
      flightPath.setMap(null);
      mapRef.current.setTilt(0);
      document.querySelector('.heatDropdown').style.height = '0';
      [...document.querySelector('.mapControls').children]
        .map(btn => btn.style.backgroundColor = colors.mainBlack);

      // sets update active control to a red color
      [...document.querySelector('.mapControls').children][activeControl]
        .style.backgroundColor = colors.mainRed;

      if (activeControl === 3) {
        mapRef.current.setTilt(45);
      } else if (activeControl === 2) {
        flightPath.setMap(mapRef.current);
        props.setNodeInfo(null);
      } else if (activeControl === 1) {
        document.querySelector('.heatDropdown').style.height = '2rem';
        props.setNodeInfo(null);
      }
    }
  }, [activeControl]);
  // ** EO EFFECT METHODS **

  /*
   * Check the amount of sensors in map points to match
  console.log('FLIGHT', props.flight.id);
  for ( let i in mapPoints ) {
    // TODO: define length to check with 
    const checkLength = 9;
    const iterate_length = Object.keys(mapPoints[i].properties.value).length;
    if (checkLength !== iterate_length) {
      console.log(iterate_length, mapPoints[i].properties.nodeId);
    }
  }
  */

  // useSuperCluster method - creates clusters on the map 
  const { clusters, supercluster } = useSupercluster({
    points: mapPoints,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  // ** UTILITY METHODS **
  // Options method setup with callback hook. Callback hook was 
  // implemented to prevent the map from flickering since the only time we 
  // need it to reload would be when the type has changed.
  const createOptions = useCallback((maps) => ({
    restriction: {
      latLngBounds: {
        north: 85,
        south: -85,
        west: -179,
        east: 180
      }
    },
    styles: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }]
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }]
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }]
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }]
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "transit",
        stylers: [{ visibility: "off" }]
      }
    ],
    mapTypeControl: true,
    rotateControl: false,
    mapTypeControlOptions: {
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [maps.MapTypeId.SATELLITE, maps.MapTypeId.ROADMAP]
    },
    mapTypeId: mapType,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT
    },
    /*
      Rewrites default library settings of minZoom.
      Values less than 4 might cause the marker calculation problem,
      therefore try to switch it around if that's the case.
    */
    minZoom: 3,
    backgroundColor: 'none'
  }), [mapType]);

  // update info of the node in the box, if the different node was selected
  const markerOnClick = (info) => {
    if (!compareCoords(info.coords)) {
      props.setNodeInfo(info);
    }
  }

  // checks if coordinates array is equal. Supposed to take [lng, lat] array
  // as each value for comparison
  const compareCoords = (nodeCoords) => {
    return (
      props.selectedCoords &&
      props.selectedCoords[0] === nodeCoords[0] &&
      props.selectedCoords[1] === nodeCoords[1]
    );
  }

  // creates the custom map controls for default view, heatmap and 3d view.
  const createMapControls = (controlDiv: Element, map: google.maps.Map, flight) => {
    // add class to controlDiv
    controlDiv.classList.add('mapControls');

    // all button settings
    const defaultMapBtn = document.createElement('div');
    const heatMapBtn = document.createElement('div');
    const map3dBtn = document.createElement('div');
    const flightPathBtn = document.createElement('div');

    // default map is selected by default
    defaultMapBtn.style.backgroundColor = colors.mainRed;
    defaultMapBtn.style.display = 'flex';
    heatMapBtn.style.display = 'flex';
    map3dBtn.style.display = 'flex';
    flightPathBtn.style.display = 'flex';
    defaultMapBtn.style.justifyContent = 'center';
    heatMapBtn.style.justifyContent = 'center';
    map3dBtn.style.justifyContent = 'center';
    flightPathBtn.style.justifyContent = 'center';

    // set index of active control to state
    controlDiv.addEventListener('click', function (e) {
      const index = [...controlDiv.children].indexOf(e.target);
      if (index > -1 && index < 3) {
        setActiveControl(index);
      }
    });

    // all icon settings
    const defImg = document.createElement('img');
    const heatImg = document.createElement('img');
    const d3Img = document.createElement('img');
    const pathImg = document.createElement('img');

    defImg.setAttribute('src', defaultMapIcon);
    heatImg.setAttribute('src', heatmapIcon);
    d3Img.setAttribute('src', map3dIcon);
    pathImg.setAttribute('src', pathIcon);
    defImg.style.pointerEvents = 'none';
    heatImg.style.pointerEvents = 'none';
    d3Img.style.pointerEvents = 'none';
    pathImg.style.pointerEvents = 'none';
    defImg.style.width = '2rem';
    heatImg.style.width = '2rem';
    d3Img.style.width = '2rem';
    pathImg.style.width = '2rem';

    // append icons to buttons
    defaultMapBtn.appendChild(defImg);
    heatMapBtn.appendChild(heatImg);
    map3dBtn.appendChild(d3Img);
    flightPathBtn.appendChild(pathImg);

    // control wrap settings
    controlDiv.style.gridTemplateColumns = '4rem 4rem 4rem';
    controlDiv.style.gridTemplateRows = '4rem 1fr';
    controlDiv.style.backgroundColor = 'rgb(20,20,20)';
    controlDiv.style.cursor = 'pointer';
    controlDiv.style.marginTop = '.5rem';
    controlDiv.style.display = 'grid';

    // append all buttons to control div
    controlDiv.appendChild(defaultMapBtn);
    controlDiv.appendChild(heatMapBtn);
    controlDiv.appendChild(flightPathBtn);
    //controlDiv.appendChild(map3dBtn);
  }
  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyCoMeZ8bHHD1zQIFSslht_znET2EEyTK0s',
          libraries: ['visualization']
        }}
        defaultCenter={{ lat: 20, lng: 0 }}
        defaultZoom={4}
        // style={mapStyles}
        options={createOptions}
        yesIWantToUseGoogleMapApiInternals
        // heatmap={{
        //   positions: activeControl === 1 && heatmapSensor.id ? heatmapData : [],
        //   options: {
        //     dissipating: 0,
        //     radius: 0.00004,
        //     opacity: 1,
        //     maxIntensity: heatmapSensor?.limit
        //   }
        // }}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapsRef.current = maps;

          // 1.3.7 add map controls on the top with new dropdown
          if (props.mapControls) {
            const controlDiv = document.createElement('div');
            createMapControls(controlDiv, mapRef.current, props.flight);

            mapRef.current.controls[mapsRef.current.ControlPosition.TOP_CENTER]
              .push(controlDiv);
          }
        }}
        onClick={() => { props.setNodeInfo() }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
        onMapTypeIdChange={(id) => { setMapType(id) }}
      >
        {activeControl !== 1 && activeControl !== 2 ?
          clusters.map(cluster => {
            const [lng, lat] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;

            if (isCluster) {
              return (
                <MarkerButton
                  className='mapMarker'
                  key={cluster.id}
                  lat={lat}
                  lng={lng}
                  selected={compareCoords([lng, lat]) ? 1 : 0}
                  onClick={(e) => {
                    e.stopPropagation();

                    const leaves = supercluster.getLeaves(cluster.id, Infinity);

                    // store the first index of leaves as initial value for avg
                    // calc
                    const avg = JSON.parse(
                      JSON.stringify(leaves[0].properties.value));
                    const min = JSON.parse(
                      JSON.stringify(leaves[0].properties.value));
                    const max = JSON.parse(
                      JSON.stringify(leaves[0].properties.value));

                    // loop through points  
                    for (let i = 1, l = leaves.length; i < l; ++i) {
                      // per sensor in point
                      for (let j in leaves[i].properties.value) {
                        const sensor = leaves[i].properties.value[j];
                        avg[j].value += sensor.value;

                        // get mins
                        if (min[j].value > sensor.value) {
                          min[j].value = sensor.value;
                        }

                        // get maxes
                        if (max[j].value < sensor.value) {
                          max[j].value = sensor.value;
                        }
                      }
                    }

                    // calc avg
                    for (let i in avg) {
                      avg[i].value /= leaves.length;
                    }

                    markerOnClick({
                      id: cluster.id,
                      coords: [lng, lat],
                      data: leaves,
                      avgTemp: null, // 'C'
                      avgHumid: null, // '%'
                      isCluster: isCluster,
                      avgPressure: null, // kPa
                      min: min,
                      max: max,
                      chartValues: avg
                    });
                  }}>
                  {pointCount}
                </MarkerButton>
              );
            }

            return (
              <MarkerButton
                key={cluster.properties.nodeId}
                lat={lat}
                lng={lng}
                selected={compareCoords([lng, lat]) ? 1 : 0}
                onClick={(e) => {
                  e.stopPropagation();

                  markerOnClick({
                    id: cluster.properties.nodeId,
                    coords: [lng, lat],
                    data: [cluster],
                    avgTemp: null, // 'C'
                    avgHumid: null, // '%'
                    isCluster: isCluster,
                    avgPressure: null, // kPa
                    min: {},
                    max: {},
                    chartValues: cluster.properties.value
                  });
                }}
              />
            );
          }) : null}
      </GoogleMapReact>
      {activeControl === 1 &&
        <MaxIntensController>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <span>
                MAX
              </span>
              <Input id="max-value" {...register("max")} />
            </div>
            <Button>Plot</Button>
          </form>
          {errors.max && <p>{errors.max.message}</p>}
        </MaxIntensController>
      }
    </>
  );
}
