import React from 'react';
import gifImage from '../Assets/404.gif';
import { Page, NotFoundWrap, Img } from '../Containers/Styles';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return(
    <Page>
      <NotFoundWrap>
        <div className='errCode'>
          <span>4</span>
          <Img width='16vw' src={gifImage} alt='notFoundGif'/>
          <span>4</span>
        </div>
        <label>
          <span>Page not found, or maybe it flew away. Try searching or go to <Link className='link' to='/'>DRIMS2 home page</Link>.</span>
        </label>
      </NotFoundWrap>
    </Page>
  );
}
