import React from 'react';
import RDP, { DateUtils } from 'react-day-picker';
import chevronLeft from '../Assets/Icons/InnerBox_Left.svg';
import chevronRight from '../Assets/Icons/InnerBox_Right.svg';
import { colors } from './Styles';
import 'react-day-picker/lib/style.css';

export default function Calendar(props) {
  const from = props.date.from;
  const to = props.date.to;

  const clickHandler = (day) => {
    // set hours to 0:00 for better date comparison
    day.setHours(0);
    props.setDate(DateUtils.addDayToRange(day, props.date))
  } 

  /*
   * FORMAT
  const modifiers = {
    flightDates: [
      {
        from: new Date(2020, 6, 2),
        to: new Date(2020, 6, 3)
      },
      {
        from: new Date(2020, 6, 7),
        to: new Date(2020, 6, 9)
      }
    ]
  };
  */

  /* to fix styles on lower res phones if needed use font-size: .9rem */
  const styles = `
    .DayPicker {
        width: 100%;
        box-sizing: border-box;
    }
    .DayPicker-wrapper {
        padding-bottom:0;
    }
    .DayPicker-Month {
        width: 100%;
        margin: 0;
    }
    .DayPicker-Caption {
        background: ${colors.mainBlack};
        padding: 1rem .5rem;
        color: white;
    }
    .DayPicker-NavButton {
        right: 0;
        top: .8rem;
    }
    .DayPicker-NavButton--prev {
        height: 1.5rem;
        width: 2rem;
        margin-right: 2.5rem;
        background-image: url(${chevronLeft});
    }
    .DayPicker-NavButton--next {
        height: 1.5rem;
        width: 2rem;
        background-image: url(${chevronRight});
    }
    .DayPicker-Body {
        color: white;
    }
    .DayPicker-Day {
        border-radius: 0;
    }
    .DayPicker:not(.DayPicker--interactionDisabled) 
    .DayPicker-Day:not(
        .DayPicker-Day--disabled):not(
            .DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: rgba(255,255,255,.1) !important;
    }
    .DayPicker-Day--selected:not(
        .DayPicker-Day--disabled):not(.DayPicker-Day--outside){
        background-color: ${colors.mainRed} !important;
    }
    .DayPicker-Day--flightDates {
    color: limegreen;
    font-weight: bold;
  }
  `;
  
  return(
    <div>
        <style>{styles}</style>
        <RDP
            selectedDays={[from, {from, to}]}
            onDayClick={clickHandler}
            modifiers={props.modifiers}/>
    </div>
  )
}
