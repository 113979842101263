import React from 'react';
import ReactSelect from 'react-select';
import { colors } from './Styles';

export default function Select(props) {

  const styles = {
      control: (base,state) => ({
          ...base,
          background: props.black ? colors.mainBlack :  'transparent',
          borderRadius: '.1rem',
          margin: 0,
          height: '3rem',
          width: props.width,
          boxShadow: 0,
          borderColor: props.black ? colors.mainBlack : 'white',
          cursor: 'pointer',
          '&:hover': {
              borderColor: 'white'
          }
      }),
      singleValue: (base,state) => ({
          ...base,
          color: 'white'
      }),
      input: (base,state) => ({
        ...base,
        color: 'white'
    }),
      placeholder: (base,state) => ({
          ...base,
          color: 'white',
          fontFamily: 'MontserratSemiBold',
          fontSize: '.9rem'
      }),
      indicatorSeparator: (base) => ({
          display: 'none'
      }),
      menu: (base,state) => ({
          ...base,
          padding: 0,
          margin: 0,
          color: 'white',
          background: 'rgba(0,0,0,.96)',
          borderRadius: 0,
          zIndex: '2',
          boxShadow: 0
      }),
      menuList: (base) => ({
          ...base,
          padding: 0,
          margin: 0
      }),
      option: (base, state) => ({
          ...base,
          cursor: 'pointer',
          backgorundColor: 'transparent',
          '&:hover': {
              backgroundColor: 'rgba(255,255,255,.2)'
          },
          backgroundColor: state.isSelected
              ? 'rgba(0,0,0, .95)' : 'transparent',
      })
  };

  return(
      <ReactSelect 
          styles={styles}
        //   isSearchable={0}
          {...props}
          />
  )
}
