import React, { useState, useEffect } from 'react';
import favActive from '../Assets/Icons/Favourite_active.svg';
import Select from '../Containers/Select';
import Calendar from '../Containers/Calendar';
import { setFavourite, getProjects } from '../Containers/calls';
import { dateParser } from '../Containers/utility';
import { doFetch } from '../Containers/calls';
import { useAuth } from '../Containers/Context/auth';
import { 
  Page, Img, ContentWrap, HomeMenu, Search, RowCheckbox, Ul, GridLi, 
  Button, StyledLink, colors
} from '../Containers/Styles';

export default function Home() { 
  console.log('** Home rendered **');
  const { resetTokens } = useAuth();
  const [projects, setProjects] = useState([]);
  const [drones, setDrones] = useState([]);
  const [selectedProject, setSelectedProject] = useState(0);
  const [filter, setFilter] = useState({
    search: '',
    dropdown: undefined,
    date: {from: null, to: null},
    favourites: 0
  });

  useEffect(() => {
    doFetch('GET', '/equipment/list', 0)
      .then(drones => {
        console.log('drones req');
        setDrones(drones);
      })
      .catch(err => {
        console.log(err)
        if (err.status === 401) {
          resetTokens();
        }
      });
  },[]);

  // send request everytime parameters are updated with 2s delay, if values are
  // default, clear timeout
  useEffect(() => {
    console.log('filter render', filter);
    const timeout = setTimeout(() => {
      let url = '/project/search?';
      if (filter.search !== '') {
        url += `name=${filter.search}&`;
        console.log('url update with name has worked');
      }
      if (filter.dropdown !== undefined) {
        url += `equipment_hash_id=${filter.dropdown}&`;
        console.log('url update with dropdown has worked');
      }
      if (filter.favourites) {
        url += `is_favourite=true&`;
        console.log('url update with favs has worked');
      }
      if (filter.date.from) {
        console.log(Math.floor(filter.date.from.getTime() / 1000) + 60*60*24);
        url += `start_timestamp=${
          Math.floor(filter.date.from.getTime() / 1000)}&`;

        if (!filter.date.to || +filter.date.from === +filter.date.to) {
          url += `end_timestamp=${Math.floor(filter.date.from.getTime() / 1000) + 60*60*24}&`;
        } else {
          url += `end_timestamp=${
            Math.floor(filter.date.to.getTime() / 1000)}&`;
        }
        console.log('url update with dates has worked');
      }

      doFetch('GET', url, 0)
        .then(projects => {
          console.log(projects);
          setProjects(projects);
        })
        .catch(err => {
          console.log(err)
          if (err.status === 401) {
            resetTokens();
          }
        });
    },500);
    
    return () => clearTimeout(timeout)
  },[filter]);
  
  /* filterMethod filters the list of projects by user inputs in Search, 
    Dropdown, Calendar and Favourites inputs. 
  const filterMethod = (key) => {
    const f = filter.favourites && !projects[key].project_favourite;
    const date = !checkDate(key);
    const d = filter.dropdown &&
      projects[key].drone_name !== filter.dropdown;
    const s = filter.search && 
      !projects[key].project_name.toLowerCase().includes(filter.search);

    if (f || date || d || s) {
      // reset filtered out, selected project
      if (+key === +selectedProject) { 
        setSelectedProject(0);
      }
      return 0;
    }
    return key;
  }

  /*
  /* checkDate checks if projects flight dates occured on calendar's selected
  date, or within selected date range. 
  const checkDate = (key) => {
    const flights = projects[key].flights, fd = filter.date;

    // If 'to' is defined, and 'from' doesn't equal 'to' range is selected.
    if (fd.to && !datesAreOnSameDay(fd.from, fd.to)) {
      for (let i in flights) {
        const flightFrom = new Date(flights[i].from), 
              flightTo = new Date(flights[i].to);

        // If any flight date fits within the selected range, return 1;
        if ((flightFrom >= fd.from && flightFrom <= fd.to)
          || (flightTo >= fd.from && flightTo <= fd.to)) {
          console.log(
            '!FLIGHT! ' + projects[key].project_name, 
            flightFrom, 
            flightTo, 
            '!fd range!', 
            fd.from, 
            fd.to);
          return 1;
        }
      }
      return 0;
    }
    /* Otherwise, if 'to' is null, OR 'to' and 'from' and equal, we check if 
    'from' is defined. If it is, then it's safe to use 'from' only. 
    else if (fd.from) {
      for (let i in flights) {
        const flightFrom = new Date(flights[i].from),
              flightTo = new Date(flights[i].to);

        /* If any flight in the project happened on that day OR if flight range
         * has filter date in between start and end date, return 1. 
        if (datesAreOnSameDay(flightFrom, fd.from) 
          || datesAreOnSameDay(flightTo, fd.from)
          || (fd.from > flightFrom && fd.from < flightTo)) {
          console.log(
            '!FLIGHT! ' + projects[key].project_name, 
            flightFrom, 
            flightTo, 
            '!fd from!', 
            fd.from);
          return 1;
        }
      }
      return 0;
    }
    else {
      return 1;
    } 
  }

  const datesAreOnSameDay = (first, second) => (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate() 
  )
    */

  // makes request to the server to set the project to favourite state, and
  // asyncronously updates the star of the project.
  const favClickHandler = (i, id) => {
    doFetch('POST', `/project/${id}/favourite`, 0)
    .then(json => {
      console.log('fav updated');
    })
    .catch(err => {
      console.log(err)
      if (err.status === 401) {
        resetTokens();
      }
    });
  }

return(
    <Page>
      <ContentWrap>
        <HomeMenu className='homeMenu' left='0'>
          <Search
            placeholder='Search Project'
            autoComplete='off'
            onChange={event => setFilter({
              ...filter, 
              search: event.target.value.trim().toLowerCase()
            })}/> 
          <Select 
            options={drones.map((drone) => {
                return {
                  label: drone.serial_number,
                  value: drone.id
                };
            })} 
            isClearable={1}
            placeholder='Select Drone'
            onChange={option => setFilter({
              ...filter,
              dropdown: option?.value
            })}/>
          <Calendar 
            date={filter.date} 
            setDate={d => setFilter({...filter, date: d})}
            modifiers={
              {
                flightDates: [].concat(...projects.map(project => {
                  return project.travel_sessions.map(ts => {
                    return {
                      from: new Date(ts.start_timestamp * 1000),
                      to: new Date(ts.end_timestamp * 1000)
                    };
                  });
                }))
              }
            }/>
          <RowCheckbox htmlFor='favId'>
            <span>Show Favourites Only</span>
            <input 
              id='favId'
              type='checkbox'
              onChange={event => {
                setFilter({...filter, favourites: +event.target.checked});
              }}/>
          </RowCheckbox>
          <Ul gap='.1rem' className='projectList'> 
            {
              projects
                .map((prj, i) => {
                  return (
                    <GridLi 
                      key={'gridLi'+i}
                      onClick={e => setSelectedProject(prj.id)}
                      selected={selectedProject === prj.id}>
                      <span>Project: {prj.name}</span>
                      <Img 
                        width='1.5rem'
                        pos='absolute'
                        right='0'
                        top='0'
                        src={favActive}
                        style={
                          !prj.is_favourite ? 
                            {filter: 'grayscale(1) brightness(50%)'} : null }
                        onClick={e => {
                          e.stopPropagation();
                          // make request to change the favourite
                          favClickHandler(i, prj.id);
                          // flip the boolean of favourite project
                          prj.is_favourite = !prj.is_favourite;
                          // assign the flipped style to the star
                          e.target.style.filter = !prj.is_favourite ? 
                            'grayscale(1) brightness(50%)' : null;
                        }}/>
                      <span>Flights: {prj.travel_sessions.length}</span>
                      <span>Length: {Math.floor(prj.time_length / 60)
                      } minutes {prj.time_length % 60} seconds</span>
                      {
                        prj.end_timestamp ? 
                        <div>
                          <span>
                            {dateParser(prj.start_timestamp * 1000, 0) + 
                              ' - ' + 
                            dateParser(prj.end_timestamp * 1000, 0)}
                          </span>
                        </div>
                        : <div>-</div> 
                      }
                    </GridLi>
                  )
                })
            }
          </Ul>
          <Button 
            justify='end'
            width='10rem'
            onClick={() => {
              if (selectedProject) {
                localStorage.setItem('projectId', selectedProject);
              }
              localStorage.removeItem('flightId');
            }}>
              { selectedProject ? 
                <StyledLink to={'/project/' + selectedProject}/> : 
                null 
              }
            SELECT
          </Button>
        </HomeMenu>
      </ContentWrap>
    </Page>
  );
}

