import React, { useState, useEffect } from 'react';
import { 
  Page, AnalysisWrap, Sensors, ChartStyle, Button 
} from '../Containers/Styles';
import Select from '../Containers/Select';
import bgImg from '../Assets/map.png';
import Sensor from '../Containers/Sensor';
import LineChart from '../Containers/LineChart';
import GoogleMap from '../Containers/GoogleMap';
import Export from '../Containers/Export';
import { doFetch } from '../Containers/calls';
import { dateParser } from '../Containers/utility';
import { useAuth } from '../Containers/Context/auth';

// props: match
export default function Analysis({ match }) {
  const { resetTokens } = useAuth();
  const projectId = match.params.project;
  const flightId = localStorage.getItem('flightId') || null;
  const [project, setProject] = useState({});
  const [selectedFlight, setSelectedFlight] = useState(flightId);
  const [flight, setFlight] = useState({});
  const [nodeInfo, setNodeInfo] = useState({
    id: null,
    coords: [],
    data: [],
    avgTemp: null, // 'C'
    avgHumid: null, // '%'
    avgPressure: null, // kPa
    chartValues: {},
    isCluster: null,
    max: {},
    min: {}
  });
  const [chartLegendFlag, setChartLegendFlag] = useState(1);

  const updateNodeInfo = (val) => {
    if (val) {
      setNodeInfo(val);
    } else {
      setNodeInfo({
        id: null,
        coords: [],
        data: [],
        avgTemp: null, // 'C'
        avgHumid: null, // '%'
        avgPressure: null, // kPa
        chartValues: {},
        isCluster: null,
        max: {},
        min: {}
      });
    }
  }

  useEffect(() => {
    const abortControl = new AbortController();

    doFetch(`GET`, `/project/${projectId}`, abortControl.signal)
      .then(project => {

        // if project has flights, pulls first flight and fills dropdown list
        // with flights
        if (project.travel_sessions.length) {
          project['flightList'] = project.travel_sessions.map(flight => ({
            label: dateParser(flight.start_timestamp * 1000, 1),
            value: flight.id
          }));
          setProject(project);
        } 
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          resetTokens();
        }
      });

    return () => {
      abortControl.abort(); 
    };
  }, []);

  useEffect(() => {
    const abortControl = new AbortController();
    
    if (selectedFlight) {
      updateNodeInfo();

      doFetch(`GET`, `/travel_session/${selectedFlight}?sort_by=timestamp`)
        .then(flight => {
          // remove sensors that are not present in the current flight
          // for consistency, and 'BAT' sensor
          for (let i = 0; i < flight.sensors.length; ++i) {
            let id = flight.sensors[i].id;

            // compare sensor id with sample sensor id. If it exists, set it
            // false as flag
            for (let j in flight.samples[0].values) {
              if (id === j) {
                id = 0;
              }
            }

            // reduce the count after splicing, additionally removes battery
            if (id || flight.sensors[i].name === 'BAT') {
              flight.sensors.splice(i, 1);
              --i;
            }
          }

          // set flight in localstorage as well
          localStorage.setItem('flightId', selectedFlight);
          setFlight(flight);
        })
        .catch(err => {
          console.log(err);
          if (err.status === 401) {
            resetTokens();
          }
        });
    }
    return () => abortControl.abort(); 
  }, [selectedFlight]);

  const displayNodeInfo = () => {
    if (flight.sensors) {
      if (nodeInfo.id) {
        return flight.sensors.map(s => (
          <Sensor 
            key={s.id} 
            name={s.name} 
            unit={s.unit}
            cluster={nodeInfo.isCluster}   
            val={nodeInfo.chartValues[s.id].value}
            min={nodeInfo.min[s.id]?.value}
            max={nodeInfo.max[s.id]?.value}
          />
        ));
      } else {
        return flight.sensors.map(s => (
          <Sensor 
            key={s.id} 
            name={s.name} 
            unit={s.unit}
          />
        ));
      }
    } else {
      return null;
    }
  }

  // displays the time/timeRange of selected point or cluster in header
  const displaySensorHeader = () => {
    if (nodeInfo.id) {
      if (nodeInfo.isCluster) {
        const timestampArray = nodeInfo.data.map(point => {
          return point.properties.nodeId;
        });
        const first = dateParser(Math.min(...timestampArray) * 1000, 1, 1);
        const last = dateParser(Math.max(...timestampArray) * 1000, 1, 1);

        return `Selected Cluster from ${
          first.substring(11, first.length)
        } to ${last.substring(11, last.length)}` 
      } else {
        const dateStr = dateParser(nodeInfo.id * 1000, 1, 1);
        return `Selected Cluster at ${dateStr.substring(11, dateStr.length)}`;
      }
    }
  }
  
  return(
    <Page>
      <AnalysisWrap>
        <ChartStyle>
          {
            project.flightList && project.flightList.length ? 
              <div className="chartHeader">
                <span>Analysis</span>
                <Select 
                  defaultValue={project.flightList.filter(f => {
                    if (f.value === selectedFlight){
                      return f;
                    }
                  })[0]}
                  placeholder='Select Flight'
                  onChange={option => {
                    setSelectedFlight(option.value);
                  }}
                  isClearable={0}
                  options={project.flightList}
                  width='11rem'
                />
                <Button 
                  onClick={() => setChartLegendFlag(!chartLegendFlag)}
                >TOGGLE LEGEND</Button>
                {
                  flight.sensors ?
                    <Export 
                      data={flight.samples} 
                      name={`node of ${project.name} at ${
                        dateParser((flight.start_timestamp + 
                          flight.timezone_offset) * 1000)}`}
                      text='EXPORT DATA'
                      node={0}
                      units={Object.fromEntries(flight.sensors.map(s => 
                        [s.id, s.unit]))}
                      offset={flight.timezone_offset}
                      timezone={flight.timezone_id}
                      width='100%'
                    />
                  : null
                }
              </div>
            : 
              <div>
                <span>Analysis</span> 
                <div style={{'display': 'flex', 'alignSelf': 'center'}}>
                </div> 
              </div>
          }
          <LineChart flightData={flight} legendFlag={chartLegendFlag}/>
        </ChartStyle>
        <section>
          <Sensors className='analysisSensors'>
            <div>
              <span>{displaySensorHeader()}</span>
            </div>
            <div>
              {displayNodeInfo()}
            </div>
          </Sensors>
          <div 
            className='analysisMap' 
            style={{overflow: 'hidden', position: 'relative'}}
          >
            <img 
              src={bgImg} 
              alt='blurred map' 
              style={{zIndex:'0', position: 'absolute', filter: 'blur(2rem)'}}
            />
            <GoogleMap 
              flight={flight} 
              setNodeInfo={updateNodeInfo} 
              selectedCoords={nodeInfo?.coords}
            />
          </div>
        </section>
      </AnalysisWrap>
    </Page>
  );
}
