import styled from "styled-components";

export const Page = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  & > li{
    display: flex;
    position: absolute; 
    bottom: 1rem;
    right: 1rem;
    color: white;
    align-items: center;

    & > a {
      display: flex;
      margin-left: 1rem;

      & > img {
        cursor: pointer;
      }
    }
  }
  & > label { 
    position: absolute;
    right: 1rem;
    bottom: 3rem;
    color: white;
    font-size: 1.5rem;
  }
`;

export const Form = styled.form`
  display: grid;
  background: linear-gradient(to right, rgb(25,25,25), 40%, rgba(25,25,25,.3) 0);
  grid-template-rows: 1fr repeat(3, max-content);
  grid-template-columns: 4fr 5fr;
  grid-row-gap: 0.5rem;
  padding: 1rem;
  color: white; 
  width: 27rem;
  max-width: 100%;
  box-sizing: border-box;

  & > div {
    display: flex;
    flex-direction: column;
    height: 3rem;
    justify-content: flex-end;
    font-size: .8rem;

    & > span {
      align-self: start;
      font-family: 'MontserratSemiBold';
      cursor: pointer;
      height: max-content;

      &:hover {
        color: rgb(0,160,255);
        text-decoration: underline;
      }
    }
  }
`;

export const FormWrap = styled.div`
  position: absolute;
  margin-top: 8rem;
  z-index: 10;
  width: min-content;
  max-width: 100%;
`;

export const Video = styled.video`
  min-width: 100%;
  z-index: 0;
  pointer-events: none;
  object-fit: cover;
  overflow: hidden;
`;

