import React, { useState, useEffect } from 'react';
import GoogleMap from '../Containers/GoogleMap';
import Select from '../Containers/Select';
import downShevron from '../Assets/Icons/InnerBox_Down.svg';
import upShevron from '../Assets/Icons/InnerBox_Up.svg';
import closeTab from '../Assets/Icons/close.png';
import Sensor from '../Containers/Sensor';
import Export from '../Containers/Export';
import { doFetch } from '../Containers/calls';
import { dateParser } from '../Containers/utility';
import { useAuth } from '../Containers/Context/auth';
import { Page, ProjectWrap, Ul, FlightMenu, NodeMenu, Sensors, 
  RowCheckbox, Img
} from '../Containers/Styles';

// props: match.params.id
export default function Project({ match }) {
  const { resetTokens } = useAuth();
  const projectId = match.params.project;
  const flightId = localStorage.getItem('flightId') || null;
  const [project, setProject] = useState({});
  const [flight, setFlight] = useState({});
  const [selectedFlight, setSelectedFlight] = useState(flightId);
  const [nodeInfo, setNodeInfo] = useState({
    id: null,
    coords: [],
    data: [],
    avgTemp: null, // 'C'
    avgHumid: null, // '%'
    avgPressure: null, // kPa
    chartValues: {},
    isCluster: null,
    max: {},
    min: {}
  });
  const [displayFlightmenuFlag, setDisplayFlightmenuFlag]= useState(1);

  // new flight request
  useEffect(() => {
    // don't call api if selectedFlight is empty. It is the case on init
    if (!selectedFlight) {
      return;
    }

    const abortControl = new AbortController();
    const reqUrl = `/travel_session/${selectedFlight}?sort_by=timestamp`;

    doFetch(`GET`, reqUrl, abortControl.signal)
      .then(flight => {

        // clear nodeInfo
        updateNodeInfo(null);
        
        // remove sensors that are not present in the current flight
        // for consistency 
        for (let i = 0; i < flight.sensors.length; ++i) {
          let id = flight.sensors[i].id;

          // compare sensor id with sample sensor id. If it exists, set it
          // false as flag
          for (let j in flight.samples[0].values) {
            if (id === j) {
              id = 0;
            }
          }

          // reduce the count after splicing, additionally removes battery
          if (id || flight.sensors[i].name === 'BAT') {
            flight.sensors.splice(i, 1);
            --i; 
          }
        }

        // set flight in localstorage as well
        localStorage.setItem('flightId', selectedFlight);
        setFlight(flight);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          resetTokens();
        }
      });
    
    return () => {
      abortControl.abort();
    };

  },[selectedFlight]);

  const updateNodeInfo = (val) => {
    if (val) {
      setNodeInfo(val);
    } else {
      setNodeInfo({
        id: null,
        coords: [],
        data: [],
        avgTemp: null, // 'C'
        avgHumid: null, // '%'
        avgPressure: null, // kPa
        chartValues: {},
        isCluster: null,
        max: {},
        min: {}
      });
    }
  }
  
  const getSensorFromChartValues = (short_name, full_name, unit) => {
    for (let i in nodeInfo.chartValues) {
      if (nodeInfo.chartValues[i].sensor_name === short_name) {
        return (
          <li>
            <strong>AVG {full_name}:</strong> {
              nodeInfo.chartValues[i].value.toFixed(3)
            } {unit}
          </li>
        )
      }
    }
    return null;
  }
  
  useEffect(() => {
    const abortControl = new AbortController();

    doFetch(`GET`, `/project/${projectId}`, abortControl.signal)
      .then(project => {
        project['flightList'] = project.travel_sessions.map(flight => ({
          label: dateParser(flight.start_timestamp * 1000, 1),
          value: flight.id
        }))
        setProject(project);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          resetTokens();
        }
      });

    return () => {
      abortControl.abort();
    };
  }, []);

  // makes request to the server to set the project to favourite state, and
  // asyncronously updates the star of the project.
  const favClickHandler = () => {
    const abortControl = new AbortController();

    doFetch('POST', `/project/${projectId}/favourite`, abortControl.signal)
    .then(json => {
    })
    .catch(err => {
      console.log(err)
      if (err.status === 401) {
        resetTokens();
      }
    });
    
    return () => {
      abortControl.abort();
    };
  }

  // displays the time/timeRange of selected point or cluster in header
  const displaySensorHeader = () => {
    if (nodeInfo.id) {
      if (nodeInfo.isCluster) {
        const timestampArray = nodeInfo.data.map(point => {
          return point.properties.nodeId;
        });
        const first = dateParser(Math.min(...timestampArray) * 1000, 1, 1);
        const last = dateParser(Math.max(...timestampArray) * 1000, 1, 1);

        return `Selected Cluster from ${
          first.substring(11, first.length)
        } to ${last.substring(11, last.length)}` 
      } else {
        const dateStr = dateParser(nodeInfo.id * 1000, 1, 1);
        return `Selected Cluster at ${dateStr.substring(11, dateStr.length)}`;
      }
    }
  }

  return(
    <Page>
      <ProjectWrap>
        <div style={{position: 'absolute', width: '100%', height: '100%'}}>
          <GoogleMap 
            flight={flight}
            selectedCoords={nodeInfo.coords} 
            setNodeInfo={updateNodeInfo}
            mapControls={1}
          />
        </div>
        { nodeInfo.id ? 
          <NodeMenu className='nodeInfo reverse_slide_right'>
            <Img 
              src={closeTab}
              pointer='1'
              top='.5rem'
              height='1rem'
              right='1rem'
              width='1rem'
              pos='absolute'
              alt='toggleFlightMenu'
              onClick={updateNodeInfo}/>
            <Ul alignContent='end'>
              <li>
                <strong>AVG Altitude:</strong> {
                  nodeInfo.chartValues.alt.value.toFixed(2)
                }
              </li>
              <li>
                <strong>AVG Latitude:</strong> {nodeInfo.coords[1]}
              </li>
              <li>
                <strong>AVG Longitude:</strong> {nodeInfo.coords[0]}
              </li>
            </Ul>
            <Sensors>
              <div>
                <span>{displaySensorHeader()}</span>
              </div>
              <div>
                {
                  flight.sensors.length && flight.samples.length ?
                    flight.sensors.map((s, i) => {
                      if (s.name !== 'BAT') {
                        return (
                          <Sensor
                            key={s.id}
                            name={s.name}
                            cluster={nodeInfo.isCluster}
                            unit={s.unit}
                            val={nodeInfo.chartValues[s.id].value}
                            min={nodeInfo.min[s.id]?.value}
                            max={nodeInfo.max[s.id]?.value}
                          />
                        );
                      }
                    }) 
                  : null
                }
              </div>
            </Sensors>
            <div style={{display: 'grid'}}>
              <Export 
                data={nodeInfo.data} 
                name={`node of ${project.name} at ${
                  dateParser(flight.start_timestamp * 1000, 1)}`} 
                node={1}
                text={'EXPORT SELECTED DATA'}
                units={Object.fromEntries(flight.sensors.map(s => 
                  [s.id, s.unit]))}
                offset={flight.timezone_offset}
                timezone={flight.timezone_id}/>
            </div>
          </NodeMenu>
          : <NodeMenu className='nodeInfo slide_right'/> 
        }
        { project.id ?
          <FlightMenu 
            className={ displayFlightmenuFlag ? 
              'reverse_slide_up' : 'slide_up' }>
            <div title={project.name}>
              <span>{project.name}</span>
            </div>
            <Ul>
              <li>
                <strong>Total Flights: </strong>
                <span>{project.travel_sessions.length}</span>
              </li>
              <li><strong>Total KM Flown: </strong>{
                project.travel_sessions.length ? 
                  project.travel_sessions
                    .map(f => f.distance)
                    .reduce((o, t) => o + t)
                    .toFixed(3)
                : null
              }</li>
              <li>
                <strong>Total Time Flown: </strong>
                {
                  project.time_length ? 
                    <span>{Math.ceil(project.time_length / 60)} minute(s)</span>
                  : null
                }
              </li>
            </Ul>
                <Select 
                  defaultValue={project.flightList.filter(f => {
                    if (f.value === selectedFlight){
                      return f;
                    }
                  })[0]}
                  placeholder='Select Flight'
                  onChange={option => {
                    setSelectedFlight(option.value);
                  }}
                  isClearable={0}
                  options={project.flightList}
                />
            {
              flight.end_timestamp ? 
                <Ul>
                  <li>
                    <strong>Total Flight Time: </strong>
                    {
                      flight.end_timestamp ? 
                        <span>{Math.ceil(
                          (flight.end_timestamp - flight.start_timestamp) / 60
                        )} minute(s)</span>
                      : null
                    }
                  </li>
                  <li>
                    <strong>Flight Start Time: </strong>
                    <span>{dateParser(flight.start_timestamp * 1000, 1)}</span>
                  </li>
                  <li>
                    <strong>Flight End Time: </strong>
                    <span>{dateParser(flight.end_timestamp * 1000, 1)}</span>
                  </li>
                  <li>
                    <strong>Lowest Altitude: </strong>
                    <span>{
                      Math.min.apply(null, 
                      flight.samples.map(function (e) { return e.altitude }))
                    }</span>
                  </li>
                  <li>
                    <strong>Highest Altitude: </strong>
                    <span>{
                      Math.max.apply(null, 
                      flight.samples.map(function (e) { return e.altitude }))
                    }</span>
                  </li>
                  <li>
                    <strong>Total Points: </strong>
                    <span>{flight.samples.length}</span>
                  </li>
                </Ul>
              : 
                <Ul></Ul>
            }
            <RowCheckbox htmlFor='favId'>
              <span>Add to Favourites</span>
              <input 
                id='favId'
                type='checkbox'
                onChange={favClickHandler}
                defaultChecked={project.is_favourite}
              />
            </RowCheckbox>
          </FlightMenu>
        :
          null
        }
        <div className='toggleFlightMenuButton' title='Toggle Flight Menu'
          onClick={() => setDisplayFlightmenuFlag(!displayFlightmenuFlag)}>
          <Img 
            src={displayFlightmenuFlag ? upShevron : downShevron} 
            pointer="1"
            alt='toggleFlightMenu'/>
        </div>
      </ProjectWrap>
    </Page>
  );
}

