import React, { useRef, useState, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// props: flightData: Object
export default function LineChart({ flightData, legendFlag }) {
  
  am4core.useTheme(am4themes_dark);
  //am4core.useTheme(am4themes_animated);
  const colors = ['#2196f3','#4caf50','#607d8b','#ffeb3b','#F4364C','#ff9800','#00bcd4',
    '#ab47bc','#795548','#e91e63','#cddc39','#ff7676','#6d712e','#673ab7','#800020',
    '#009688','#3f51b5','#76ff03','#ffff8d','#d50000','#FA4616','#82A3FF','#9873AC',
    '#CDC4AA','#862633','#D9027D','#FFBCD9','#36454F','#ADDFB3','#FFB673','#5D3754',
    '#AC9F3C','#06038D','#44693D','#3C1321','#36454F','#9FE2BF','#CB6015','#2E1A47',
    '#154734'
  ];
  /** 40 colors
   * blue, green, bluegrey, yellow, Amaranth, orange, cyan, 
   * purple, brown, pink, lime, salmonPink, oliveGreen, deepPurlple, burgendy,
   * teal, indigo, lightGreen, lightYellow, red, orangeRed, CornflowerBlue, lavender,
   * oatmeal, Maroon, Fuchsia, cottonCandyPink, CharcoalGray, Celadon,apricot, eggplant,
   * brass,darkblue, hunterGreen, darkChocoBrown, CharcoalGray, SeafoamGreen, burntOrange, midnightPurple, 
   * darkGreen
   *  */ 
  const chart = useRef(null);

  const addSeries = (ch, yAxes, sensor, color) => {
    // add lines to the chart
    let line = ch.series.push(new am4charts.LineSeries());
    line.dataFields.dateX = "date";
    line.name = sensor.name;
    line.fill = color;
    line.stroke = color;
    line.dataFields.valueY = sensor.name;
    line.strokeWidth = 2;
    line.tooltipText = "{name}: {valueY}{yAxis.name}";
    line.yAxis = yAxes[sensor.unit];

    // display yAxes of the line when its toggled on
    line.events.on('shown', function() {
      // change flag since chart doesn't change it by default for some
      // reason
      line.hidden = 0;

      // display yAxis
      line.yAxis.disabled = 0;
    });

    // hides yAxis when when all related series are hidden 
    line.events.on('hidden', function() {
      // changes flag since chart doesn't change it by default for some
      // reason
      line.hidden = 1;

      // loops through list of sensors in the chart and checks if 
      // the other sensors with the same yAxis are hidden. If at least
      // one is showing it will keep the yAxis. 
      for(let j in ch.series.values) {
        if (ch.series.values[j].yAxis.name === sensor.unit &&
          !ch.series.values[j].hidden && 
          ch.series.values[j].name !== 'Toggle All') {
          return;
        }
      }

      // If all of them are hidden it will hide the yAxis as well.
      line.yAxis.disabled = 1;
    });
  
    // add bullets
    //let bullet = line.bullets.push(new am4charts.CircleBullet());
  };
  const addYAxis = (ch, yAxes, sensor, yAxisLocation) => {
    let valueAxis = ch.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = yAxisLocation; 
    valueAxis.tooltip.disabled = true;
    valueAxis.name = sensor.unit;
    valueAxis.layout = 'absolute';
    valueAxis.title.rotation = 0;
    valueAxis.title.align = 'center';
    valueAxis.title.valign = 'bottom';
    valueAxis.title.dy = 40;
    valueAxis.tooltip.dx = 45;
    valueAxis.renderer.line.strokeOpacity = 1;
    
    if (!legendFlag) {
      valueAxis.renderer.line.disabled = true; //disables axis line
      valueAxis.renderer.labels.template.disabled = true; //disables labels
      valueAxis.renderer.grid.template.disabled = true;  //disables grid
    } else {
      valueAxis.title.text = `${sensor.unit}`;
    }

    yAxes[sensor.unit] = valueAxis;
  };

  // adds one more empty series to have toggle button in legend
  const addToggleSensors = (ch, yAxes) => {
    if (flightData.sensors) {
      let toggle = ch.series.push(new am4charts.LineSeries());
      toggle.name = 'Toggle Sensors';
      toggle.dataFields.dateX = "date";
      toggle.fill = '#ffffff';
      toggle.stroke = '#ffffff';
      toggle.dataFields.valueY = 'void';
      toggle.yAxis = yAxes[flightData.sensors[0].unit];

      toggle.events.on('hidden', function() {
        for (let i in ch.series.values) {
          ch.series.values[i].hide();
          ch.series.values[i].hidden = 1;
        }
        for (let i in ch.yAxes.values) {
          ch.yAxes.values[i].disabled = 1;
        }
      });

      toggle.events.on('shown', function() {
        for (let i in ch.series.values) {
          ch.series.values[i].show();
          ch.series.values[i].hidden = 0;
        }
        for (let i in ch.yAxes.values) {
          ch.yAxes.values[i].disabled = 0;
        }
      });
    }
  }

  // creation of the chart
  useLayoutEffect(() => {
    if (!flightData || !flightData.samples) {
      return;
    }

    let ch = am4core.create("chartDiv", am4charts.XYChart);
    am4core.options.autoSetClassName = true;
    am4core.DateFormatter.timezone = flightData.timezone_id;
    let data = [];
    let visits = 10;

    // get points 
    for (let i in flightData.samples) {
      let sample = {
        date: new Date(flightData.samples[i].timestamp * 1000)
      };

      for (let j in flightData.samples[i].values) {
        const value = flightData.samples[i].values[j];

        // remove battery
        if (value.sensor_name !== 'BAT') {

          sample[value.sensor_name] = value.value;
        }
      }

      // Add alitiude to data separately
      sample['ALT'] = flightData.samples[i].altitude; 

      data.push(sample);
    }

    // add data
    ch.data = data;

    // add xAxis
    let dateAxis = ch.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.groupData = true;

    // add yAxes
    let yAxes = {};
    for (let i in flightData.sensors) {
      let sameUnitFlag = 0;
      const sensor = flightData.sensors[i];

      // find unique unit to create yAxis with
      for (let j in yAxes) { 
        if (j === sensor.unit) { 
          sameUnitFlag = 1;
          break;
        }
      }

      // if the unit is unique
      if (!sameUnitFlag) {
        addYAxis(ch, yAxes, sensor, i%2);
      }
    }

    addToggleSensors(ch, yAxes);
    
    // add lines 
    for (let i in flightData.sensors) {
      addSeries(ch, yAxes, flightData.sensors[i], colors[i]);
    }

    // add Altitude to the charts 
    // 1. yAxis
    addYAxis(ch, yAxes, { name: 'ALT', unit: 'M' }, 1);

    // 2. series
    addSeries(ch, yAxes, { name: 'ALT', unit: 'M' }, '#ffffff');

    // add legend
    if (legendFlag) {
      ch.legend = new am4charts.Legend();
      ch.legend.labels.template.text = '[bold]{name}[/]';
    } else {
      console.log(ch.yAxes);
      //ch.yAxes.disabled = true; //disables axis line
    }

    // add cursor
    ch.cursor = new am4charts.XYCursor();

    // save chart ref
    chart.current = ch;

    return () => {
      ch.dispose();
    };
  }, [flightData, legendFlag]);

  return (
    <div 
      className='chartDiv' 
      style={{height: '100%', color: 'white', zIndex: '1'}}></div>
  ); 
}

