import React from 'react';
import { useLocation } from 'react-router-dom';
import MenuIcon from '../Assets/Icons/Calendar.svg';
import ProjectIcon from '../Assets/Icons/Map_main.svg';
import AnalysisIcon from '../Assets/Icons/Analysis.svg';
//import AlertsIcon from '../Assets/Icons/Mail.svg';
//import ActiveAlertsIcon from '../Assets/Icons/Mail_Notification.svg';
import LogoutIcon from '../Assets/Icons/Logout.svg';
import Guide from './Guide';
import { StyledLink, colors, Img, NavLi, Nav, GuideStyle } from './Styles';

// props: newAlerts, logOut
export default function Navbar(props) {
  const projectId = localStorage.getItem('projectId') || 0;

  // get the current path
  const location = useLocation().pathname;
  const listItems = [
    { 
      icon: MenuIcon, 
      link: '/', 
      status: 1,
      name: 'Home'
    },
    { 
      icon: ProjectIcon, 
      link: `/project/${projectId}`, 
      status: projectId,
      name: 'Project'
    },
    { 
      icon: AnalysisIcon, 
      link: `/analysis/${projectId}`, 
      status: projectId,
      name: 'Analysis'
    },
    /*
    {
      icon: (props.newAlerts ? ActiveAlertsIcon : AlertsIcon),
      link: '/alerts', 
      status: 1,
      name: 'Alerts'
    },
    */
  ];

  // map function
  const listItemsMap = (item, i) => {
    return (
      <NavLi
        key={'navIcon' + i}
        bg={location === item.link ? colors.mainRed : null}
        pointerEvents={item.status ? 'auto' : 'none'}
        brightness={item.status ? '100%' : '40%'}
        title={item.name}
      >
        <GuideStyle/>
        <StyledLink to={item.link}/>
        <Img
          src={item.icon}
          width='1.7rem'
          alt={'navIcon' + item.link}
        />
      </NavLi>
    )
  }

  return (
    <Nav className='navList' background={colors.mainBlack}>
      {listItems.map(listItemsMap)}
      <Guide location={location}/>
      <NavLi onClick={props.logOut} title='Logout'>
        <Img src={LogoutIcon} width='1.7rem' alt='navIcon/Logout'/>
      </NavLi>
    </Nav>
  );
}
