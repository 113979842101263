import React from 'react';
//import useSwr from 'swr';

const baseUrl = 'https://scentroid.dev';
//const baseUrl = 'http://192.168.1.141:3000';
export const myHeaders = {
  Accept: 'application/json',
  'Content-type': 'application/json'
}

// global and generic fetch for one time requests.
// TODO: remove prod when backend is figured out
export const doFetch = async (method, path, signal, data) => {
  if (!path && !method) {
    throw new Error('Path and method are needed');
  }
  
  // add authorization 
  if (localStorage.token) {
    myHeaders['Authorization'] = 'Bearer ' + localStorage.token;
  } 

  // prepare parameters
  const url = baseUrl + path;
  const options = {
    method: method,
    body: JSON.stringify(data),
    credentials: 'include',
    headers: myHeaders
  }

  // adds signal to options
  if (signal) {
    options['signal'] = signal;
  }

  // make request
  const resp = await fetch(url,options);
  
  // handle errors
  if (resp.ok) {
    return resp.json();
  } else if (resp.status === 400) {
    // if status is 400 throw the text of the response built on backend
    const text = await resp.text();
    throw new Error(text);
  } else {
    // if any other error, just throw it and read statusText
    const err = new Error(resp.statusText);
    err.status = resp.status;
    throw err;
  }
}

// * generic get request for swr for continuous data pulling
// TODO: remove prod when backend is figured out
/*
export const useReq = (path, prod, options) => {
  if (!path) {
    throw new Error('Path is needed');
  }
  
  const url = prod ? prodUrl + path : baseUrl + path;
  const { data, error } = useSwr(url, options);

  if (error) {
    return 'req of ' + path + ' has ' + error;
  }

  return data;
}

export const GetMapData = () => {
  const url = 'https://scentroid.dev/equipment/get_scentinals_map';
  const { data, error } = useSwr(url);

  if (error) {
    return <span>{ error }</span>;
  }

  if (!data) {
    return <span>Loading...</span>;
  }

  return data;
}
*/

