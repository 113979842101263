import React from 'react';
import exportData from '../Assets/Icons/exportData.svg';
import { dateParser } from './utility';
import { Button, Img } from './Styles';

// props: (data: Object, units: Array, name: String, node: Bool, text: String,
// timezone: String)
export default function Export({ data, units, name, node, text, offset, 
  timezone, width}) {

  const onClick = () => {
    // 1. build data
    let csv = `data:text/csv;charset=utf-8,`;
    // 1.1 default headers
    csv += `Date,Time ${timezone},Timestamp,`;
    // 1.2 according to the node boolean build appropriate data
    csv = node ? buildNodeData(csv) : buildFlightData(csv);

    // encode csv
    const encodedUri = encodeURI(csv);
    
    // create button in dom with download method, click it and remove it
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const buildNodeData = (csv) => {
    // 1.1 build name headers
    for (let i in data[0].properties.value) {
      csv += `${data[0].properties.value[i].sensor_name},`;
    }
    csv += `Longitude,Latitude\n`;
    
    // 1.2.1 add altitude unit since it was added to sensors after request 
    units['alt'] = '-';
    // 1.2.2 build unit name headers
    csv += `MM/DD/YYYY,HH:MM:SS,Epoch,`;
    for (let i in data[0].properties.value) {
      csv += `${units[i]},`;
    }
    // 1.2.3 add dashes for units of longitude and latitude units
    csv +=`-,-,\n`;

    // 1.3 Add data to csv
    for (let i in data) {

      // 1.3.1 date, time, timestamp first
      const timestampWithOffset = data[i].properties.nodeId + offset;
      const date = dateParser(timestampWithOffset * 1000, 1, 1);
      csv += `${date.slice(0, 10)},`;
      csv += `${date.slice(date.length - 8)},`;
      csv += `${data[i].properties.nodeId},`;

      // 1.3.2 sensor values second
      for (let j in data[i].properties.value) {
        csv += `${data[i].properties.value[j].value},`;
      }

      // 1.3.3 add coords at the end
      csv += `${data[i].geometry.coordinates[0]},`;
      csv += `${data[i].geometry.coordinates[1]},\n`;
    }

    return csv;
  }

  const buildFlightData = (csv) => {
    // 1.1 build name headers from the first sample of data
    for (const i in data[0].values) {
      csv += `${data[0].values[i].sensor_name},`;    
    }
    csv += `Altitude,Longitude,Latitude\n`;

    // 1.2 Build unit name headers from @units
    csv += `MM/DD/YYYY,HH:MM:SS,Epoch,`;
    for (const i in data[0].values) {
      csv += `${units[i]},`;
    }
    csv += `-,-,-\n`;

    // 1.3 add data to csv
    for (const i in data) {
      // 1.3.1 date, time, timestamp first
      const timestampWithOffset = data[i].timestamp + offset;
      const date = dateParser(timestampWithOffset * 1000, 1, 1);
      csv += `${date.slice(0, 10)},`;
      csv += `${date.slice(date.length - 8)},`;
      csv += `${data[i].timestamp},`;

      // 1.3.2 sensor values second
      for (let j in data[i].values) {
        csv += `${data[i].values[j].value},`;
      }

      // 1.3.3 lat lng alt 
      csv += `${data[i].altitude},`;
      csv += `${data[i].longitude},`;
      csv += `${data[i].latitude},\n`;
    }

    return csv;
  }

  return (
    <Button 
      className='exportButton'
      justify='end'
      onClick={onClick}
      width={width}
    >
      <span style={{'marginRight': '.5rem'}}>{text}</span>
      <Img
        src={exportData} 
        alt='exportBtn'
        width='1.3rem'
      />  
    </Button>
  );
}
