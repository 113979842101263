import styled, { createGlobalStyle, keyframes } from "styled-components";
import Audiowide from '../Assets/Fonts/Audiowide.ttf';
import MontserratRegular from '../Assets/Fonts/Montserrat-Regular.ttf';
import MontserratBold from '../Assets/Fonts/Montserrat-Bold.ttf';
import MontserratSemiBold from '../Assets/Fonts/Montserrat-SemiBold.ttf';
import searchIcon from '../Assets/Icons/InnerBox_Search.svg';
import renameIcon from '../Assets/Icons/Rename.svg';
import bgImg from '../Assets/map.png';
import { Link } from 'react-router-dom';

export const colors = {
  mainRed: 'rgb(227, 50, 46)',
  mainGrey: 'rgb(217,215,215)',
  mainBlack: 'rgb(24,22,25)',
  mainHover: 'rgba(150,150,150,.4)',
  mainYellow: '#F9B513'
}

// Fonts
export const Fonts = createGlobalStyle`
  @font-face {
      font-family: Audiowide;
      src: url(${Audiowide});
  }
  @font-face {
      font-family: Montserrat;
      src: url(${MontserratRegular});
  }
  @font-face {
      font-family: MontserratSemiBold;
      src: url(${MontserratSemiBold});
      font-weight: semi-bold;
  }
  @font-face {
      font-family: MontserratBold;
      src: url(${MontserratBold});
      font-weight: bold;
  }
`;

// keyframes 
const expand = keyframes`
  from {
    transform: scale(0);
  }
`;
const slide_up = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
`;
const reverse_slide_up = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;
const slide_right = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;
const reverse_slide_right = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

// Custom font application for introjs tooltip
export const GuideStyle = createGlobalStyle`
  .introjs-tooltip, .introjs-button {
    font-family: Montserrat !important;
  }
`;

export const CompanyLabel = styled.label`
  font-family: Audiowide;
  font-size: ${ props => props.fontSize };
  grid-column-start: ${ props => props.colStart };
  grid-column-end: ${ props => props.colEnd };
  text-align: ${ props => props.textAlign };
`;

// Pages
export const Main = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(35,35,35);
  font-family: Montserrat;
`;
export const Page = styled.div`
  position: relative;

  &::before {
    content: '';
    background-image: url(${bgImg});
    filter: blur(2rem);
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

// Content Wrap
export const ContentWrap = styled.div`
  display: grid;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: absolute;
`;
export const AlertsWrap = styled(ContentWrap)`
  grid-template-columns: minmax(0,20rem) 1fr;
`;
export const AnalysisWrap = styled(ContentWrap)`
  padding: .5rem;
  background: rgb(66,68,55);
  grid-template-rows: minmax(27rem, 1fr) minmax(27rem, 1fr);
  grid-gap: .5rem;

  /* Analysis sensors and map */
  & > section {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-auto-rows: minmax(16rem, 1fr);
  }
`;
export const ProjectWrap = styled(ContentWrap)`
  // TODO: delete when done
  //grid-template-columns: minmax(0,20rem) 1fr;  
  
  & .toggleFlightMenuButton {
    display: flex;
    background: rgba(0,0,0,.8);
    border: 1px solid rgba(255,255,255, .2);
    width: 2rem;
    height: 3rem;
    position: absolute;
    padding: .5rem;
    right: 0;
    margin-top: .5rem;
    align-items: center;
    cursor: pointer;
    z-index: 2;
  }
`;
export const NotFoundWrap = styled(ContentWrap)`
  grid-template-rows: max-content 1fr;
  background: rgb(45,45,45) !important;

  & > .errCode {
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 5% 25% 5%;
    font-size: 18vw;
    color: #fbfbfb;
    width: 50%;
    grid-template-columns: 1fr 1fr 1fr;
    user-select: none;

    & > img {
      border-radius: 50%;
    }
  }

  & > label {
    display: grid;
    position: relative;
    color: #fbfbfb;
    font-size: 3vh;
    align-items: top;
    margin: 0 10%;
    justify-items: center;
    line-height: 5vh;

    & > span > .link {
      font-size: 4vh;
      color: #fbfbfb;
      font-family: 'MontserratSemiBold'; 

      &:hover {
        text-decoration: underline;
        color: rgb(0, 160, 255);
      }
    }
  }
`;

// Inputs
export const Input = styled.input`
  grid-column-start: 1;
  padding-left: 8px;
  border: 0;
  box-sizing: border-box;
  height: ${ props => props.height };
  background: ${ props => props.bg };
  grid-column-end: ${ props => props.colEnd };
  
  &::placeholder {
      font-size: .9rem;
      font-family: MontserratSemiBold;
  }
  &::-ms-input-placeholder {
      font-weight: bold;
  }
`;
export const MenuInput = styled(Input)`
  height: 3rem;
  color: white;
  padding-right: 2rem;
  border: .08rem solid rgba(255,255,255,.8);
  border-radius: .1rem;
  background-color: transparent;
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: 97% 55%;

  &::placeholder {
      color: white;
      opacity: 1;
  }
  &::-ms-input-placeholder {
      color: white;
  }
`;
export const Search = styled(MenuInput)`
  background-image: url(${searchIcon});
`;
export const Write = styled(MenuInput)`
  background-image: url(${renameIcon});
`;

// Button
export const Button = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;
  padding: 0 .5rem;
  color: white;
  cursor: pointer;
  border: 0;
  font-family: MontserratSemiBold;
  width: ${ props => props.width };
  height: ${ props => props.height };
  justify-self: ${ props => props.justify };
  background: ${ props => props.theme.main };
  align-self: ${ props => props.align };
  font-size: .9rem;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    box-shadow: inset 0 0 .5rem .2rem rgb(0,0,0);
  }
`;
Button.defaultProps = {
    theme: {
        main: colors.mainRed 
    }
};
export const SecondaryButton = styled(Button)`
  display: flex;
  padding: 0 10px;
  font-size: .9rem;
  justify-content: start;
  background: transparent;
  border: .08rem solid rgba(255,255,255,.8);
  border-radius: .1rem;
  background-size: 1.7rem;
  background-repeat: no-repeat;
  background-position: 96% 45%;
  background-image: url(${ props => props.icon });
`;

// Error 
export const Error = styled.div`
  padding: .5rem 1.5rem;
  box-sizing: border-box;
  color: ${ props => props.color ? props.color : 'white'};
  width: ${ props => props.width };
  height: ${ props => props.height };
  background: ${ props => 
    props.warning ? props.theme.warning : props.theme.default };
  margin: ${ props => props.margin };
`;
Error.defaultProps = {
  theme: {
    default: colors.mainRed,
    warning: colors.mainYellow
  }
};

// Unordered List
export const Ul = styled.ul`
  display: grid;
  grid-auto-rows: max-content;
  overflow: auto;
  margin: 0;        
  padding: 0;
  width: ${ props => props.width };
  grid-gap: ${ props => props.gap };
  height: ${ props => props.height };
  max-height: ${ props => props.maxHeight };
  align-content: ${ props => props.alignContent};

  /* scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgb(100,100,100) transparent;

  &::-webkit-scrollbar {
      width: .3rem;
  }

  &::-webkit-scrollbar-thumb {
      background-color: rgb(100,100,100);
  }
`;

export const Nav = styled(Ul)`
  z-index: 10;
  background: ${ colors.mainBlack };
`;

// List Items
export const Li = styled.li`
  display: grid;
  position: relative;
  cursor: pointer;
  color: white;
  background: ${ props => props.bg };
`;
export const NavLi = styled(Li)`
  height: 4rem;
  justify-items: center;
  align-items: center;
  pointer-events: ${ props => props.pointerEvents };
  filter: brightness(${ props => props.brightness });

  &:hover {
      background: ${ colors.mainHover };
  }
`;
export const GridLi = styled(Li)`
  grid-gap: .1rem;
  font-size: .8rem;
  padding: .5rem;
  margin-right: 5px;
  box-sizing: border-box;
  height: ${ props => props.height };
  background-color: ${ colors.mainBlack };
  display: ${ props => props.display };
  border-left: .2rem solid ${ props => 
    props.selected ? 
      colors.mainRed : 'transparent' 
  };
  animation: ${expand} .5s ease-in-out;
  
  &>span:first-child {
    font-weight: bold;
  }

  & span {
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & div:last-child {
    justify-self: center;
  }
`;

// Image
export const Img = styled.img`
  align-self: center;
  top: ${props => props.top ? props.top : 0};
  user-drag: none;
  user-select: none;
  cursor: ${ props => props.pointer ? 'pointer' : 'auto' };
  height: ${ props => props.height };
  right: ${ props => props.right };
  width: ${ props => props.width };
  position: ${ props => props.pos };
  --webkit-filter: ${ props => props.webkitFilter };
  object-fit: ${ props => props.objectFit };
  z-index: ${ props => props.zindex };
`;
export const BgImg = styled(Img)`
  position: absolute;
  filter: blur(2rem);
  height: 100%;
  width: 100%;
`;

// Menu
export const Menu = styled.div`
  display: grid;
  margin-left: ${ props => props.hide ? '-20rem' : '0' };
  color: white;
  grid-gap: .5rem;
  width: 100%; 
  max-width: 20rem;
  padding: .5rem;
  box-sizing: border-box;
  background: rgba(0,0,0,.5);
  right: ${ props => props.right };
  height: 100%;
  transition: margin 1s ease;
`;
export const ProjectMenus = styled(Menu)`
  background: rgba(0,0,0,.9);
  box-shadow: inset 0 0 200rem rgba(150,150,150, .35);
  z-index: 2;

  @supports (backdrop-filter: blur(10px)) or 
    (-webkit-backdrop-filter: blur(10px)) 
  {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(0,0,0,.5);
    box-shadow: none;
  }
`;
export const NodeMenu = styled(ProjectMenus)`
  grid-template-rows: 4rem 1fr 2.5rem;
  min-height 100%;
  position: absolute;
  z-index: 3;

  &.reverse_slide_right{
    animation: ${reverse_slide_right} 1s forwards;
  }

  &.slide_right{
    animation: ${slide_right} 1s forwards;
  }
  & ul {
    padding-left: .5rem;
    
    & > li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
export const FlightMenu = styled(ProjectMenus)`
  margin-left: auto;
  height: 100%;
  max-height: 27rem;
  width: 100%;
  max-width: 22em;
  overflow: hidden;
  grid-template-rows: 3rem max-content 3rem minmax(0, 1fr) 3rem;
  z-index: 1;
  position: relative;

  &.reverse_slide_up{
    animation: ${reverse_slide_up} 1s forwards;
  }

  &.slide_up{
    animation: ${slide_up} 1s forwards;
  }

  &>div:first-child {
    display: flex;
    overflow: hidden;
    justify-content: start;
    width: 90%;
    font-size: 2rem;
    padding-left: .5rem;
    align-items:center;
    font-family: MontserratSemiBold;

    & span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & ul {
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    border-bottom: .1rem solid white;
    padding: .5rem; 
    transition: height 1s ease;
  }
`;
export const HomeMenu = styled(Menu)`
  min-height: 48rem;
  grid-template-rows: repeat(2, 3rem) 19rem 3rem minmax(0, 1fr) 2.5rem;
`;
export const AlertsMenu = styled(Menu)`
  min-height: 100%;
  grid-template-rows: 3rem 2rem 3rem minmax(0,1fr) 2.5rem;
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 10;
`;

// Router Link
export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  color: white;
  text-decoration: none;
`;

// Checkbox
export const RowCheckbox = styled.label`
  display: flex;
  height: 3rem;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  border: .08rem solid rgba(255,255,255,.8);
  font-family: MontserratSemiBold;
  font-size: .9rem;
  cursor: pointer;
  user-select: none;

  & input {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
  }
`;

// Alerts
export const LastSync = styled.span`
  user-select: none;
  font-size: .95rem;
  font-style: italic;
  text-align: end;
  border-bottom: .2rem solid white;
  margin: 0 -.5rem;
  padding: 0 .5rem;
`;

export const AlertWindow = styled.div`
  display: grid;
  grid-template-rows: 3rem 1fr;
  visibility: ${ props => props.hide ? 'hidden' : 'visible' };
  color: white;
  height: 90%;
  width: 40rem;
  max-width: 100%;
  z-index: 20;
  margin: 2rem auto 0;
  background: rgba(0,0,0,.4);

  &>div:nth-child(2) {
    padding: 2rem;
  }

  @media (max-width: 960px) {
    box-shadow: 0 0 .3rem .3rem rgba(0,0,0,.95);
    grid-column: 1/3;
    grid-row: 1/2;

    &>div:first-child {
      background: rgb(0,0,0);
    }

    &>div:nth-child(2) {
      background: rgba(45,45,45,.98);
    }
  }
`;

export const AlertHeader = styled.div`
  display: flex;
  padding: .5rem;
  justify-content: space-between;
  align-items: center;
  background: rgba(0,0,0,.4);

  &>span:first-child {
    font-weight: bold;
  }

  &>span:nth-child(2) {
    font-size: .9rem;
  }
`;

// Circle
export const Circle = styled.span`
  position: absolute;
  right: .2rem;
  top: .2rem;
  width: .5rem;
  height: .5rem;
  background: darkgrey;
  border-radius: 50%;
`;

// Chart
export const ChartStyle = styled.div`
  display: grid;
  grid-template-rows: minmax(4rem, max-content) minmax(4rem, 1fr);
  background: rgba(0,0,0,.4);
  margin-bottom: 1rem;
  position: relative;
  color: white;
  height: 100%;

  /* linechart header */
  &>div:first-child {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 11rem));
    grid-auto-rows: 3rem;
    justify-content: end;
    grid-gap: .5rem;
    background: ${colors.mainBlack};
    padding: .5rem .5rem .5rem 5.5rem;
    box-sizing: border-box;

    &>span {
      position: absolute;
      left: 1rem;
      top: 1.5rem;
      font-family: MontserratSemiBold;
    }
  }
`;

export const Sensors = styled.div`
  display: grid;
  grid-template-rows: 3rem 1fr;
  grid-gap: .5rem;
  color: white;
  overflow: hidden;

  /* HEADER */
  &>div:first-child {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    background: ${ colors.mainBlack };
    font-family: MontserratSemiBold;
    font-size: .9rem;
    padding: .4rem 1rem;
  }

  /* BODY */
  &>div:nth-child(2) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)); 
    grid-auto-rows: max-content;
    overflow-y: auto;
    grid-gap: .5rem;
    padding-right: .4rem;

    /* SCROLLBAR */
    scrollbar-width: thin;
    scrollbar-color: rgb(100,100,100) transparent;

    &::-webkit-scrollbar {
        width: .3rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(100,100,100);
    }

    /* SENSOR */
    &>div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 6rem;
      background: rgba(20,20,20,1);
      box-sizing: border-box;
      padding: .4rem .4rem .4rem .6rem;
      align-items: center;
      position: relative;

      /* ALL TEXT */
      & span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      /* SENSOR NAME */
      & span:first-child {
        font-family: MontserratSemiBold;
      }

      /* SENSOR VALUES */
      &>div {
        display: flex;
        flex-direction: column;
      }

      /* AVG or RAW icon */
      & > img {
        position: absolute;
        width: 2rem;
        right: .2rem;
        top: -.1rem;
      }
    }
  }
`;

export const Gauge = styled.div`
  display: block;
  width: 100%;
  max-width: ${ props => props.width };
  min-width: 8rem;
  align-self: flex-end;
  position: relative;

  /* unit */
  & > span {
    position: absolute;
    bottom: -.7rem;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    font-size: .8rem;
    color: grey;
  }
  
  /* gauge body */
  &>div {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: rgba(223,223,223);
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow:hidden;

    /* gauge fill */
    &>div:first-child {
      position: absolute;
      top: 100%;
      width: inherit;  
      height: 110%;
      background: ${ props => props.fill };
      transform-origin: center top;
      transform: rotate(${ props => props.turn }turn);
      transition: 0.5s ease-out;
    }

    /* label */
    &>div:nth-child(2) {
      background: rgb(20,20,20);
      position: absolute;
      width: 60%;
      height: 130%;
      border-radius: 50%;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);

      /* text */
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20%;
      box-sizing: border-box;
    }
  }
`;

export const MarkerButton = styled.div.attrs( props => ({
  tabIndex: '0'
}))`
  background-color: ${ props => 
    props.selected ? 'rgb(180, 50, 50)' : 'rgb(50, 50, 50)'};
  box-shadow: inset -.3rem -.2rem .5rem rgba(0,0,0,.9);
  border: 2px solid rgba(255,0,0,.5);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Montserrat;
  cursor: pointer;
  margin-left: -1rem;
  margin-top: -1rem;
  font-size: 1rem;
`;

export const MaxIntensController =styled.div`
width: 12rem;
height: 4rem;
position: fixed;
top: 0.5rem;
left: 30%;
@media only screen and (max-width: 1366px) {
  left: 25%;
}
@media only screen and (max-width: 1024px) {
  left: 5rem;
}
background-color: rgb(20,20,20);
border-radius: .2rem;
color: rgb(255, 255, 255);
display: flex; 
justify-content: space-between;
flex-direction: column;
& > form > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
  }
}
& Input {
  height: 2rem;
  width: 8rem;
  color: white;
  border-left: .08rem solid rgba(255,255,255,.8);
  border-radius: .1rem;
  background-color: transparent;
  &::placeholder {
      color: white;
      opacity: 1;
  }
  &::-ms-input-placeholder {
      color: white;
  }
}
& Button {
  width: 100%;
  height: 2rem;
  display: flex;
  padding: 0 10px;
  font-size: 17px;
  justify-content: center;
  background-color: #F9B513;
  border-radius: 0 0 .1rem .1rem;
  &:hover {
    background-color: rgba(150,150,150,.4);
  }
}
`
