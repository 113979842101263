import React, { useState } from 'react';
import loginVid from '../Assets/loginVideo.m4v';
import loginIcon from '../Assets/Icons/Login.svg';
import mailIcon from '../Assets/Icons/Mail.svg';
import yt from '../Assets/Icons/youtube.svg';
import li from '../Assets/Icons/linkedin.svg';
import twit from '../Assets/Icons/twitter.svg';
import ig from '../Assets/Icons/instagram.svg';
import fb from '../Assets/Icons/facebook.svg';
import { Page, Form, FormWrap, Video } from './CustomStyles/Login';
import { Input, Button, Error, CompanyLabel, Img } from '../Containers/Styles';
import { useForm } from 'react-hook-form';
import { useAuth } from '../Containers/Context/auth';
import { Redirect } from 'react-router-dom';
import { doFetch } from '../Containers/calls';

export default function Login() {
  const [ loginError, setLoginError ] = useState(0);
  const [ warning, setWarning ] = useState(0);
  const { register, handleSubmit } = useForm();
  const { authToken, setAuthToken } = useAuth();

  // LOGIN
  const onSubmit = (data) => {
    doFetch('POST', '/auth/login', null, data)
      .then(json => {
        console.log(json);
        setAuthToken(json.token.id);
      })
      .catch((err) => {;
        setLoginError(err.message);
      });
  }

  const forgotPassword = () => {
    setWarning(`To update your password please contact our team at `);
  }

  return(
    <Page>
      <FormWrap>
        { authToken ? <Redirect to='/'/> : null }
        { loginError ? <Error>{loginError}</Error> : null}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CompanyLabel 
            colStart='2' 
            colEnd='3' 
            fontSize='2.5rem'
            textAlign='end'>DRIMS2</CompanyLabel>
          <Input 
            placeholder='EMAIL'  
            {...register('email')}
            height='2rem'
            colEnd='3'/>
          <Input 
            placeholder='PASSWORD'
            type='password'
            {...register('password')}
            height='2rem'
            colEnd='3'/>
          <div>Forgot Password? 
            <span onClick={forgotPassword}>Start here.</span>
          </div>
          <Button width='8rem' height='2rem' justify='end' align='end'>
            <span>SIGN IN</span>
            <Img src={loginIcon} alt='login' width='1rem'/>
          </Button>
        </Form>
        { warning ? 
          <Error warning='1' color='black'>
            {warning} 
            <a 
              href='mailto:support@scentroid.com' 
              alt='supportEmail'>support@scentroid.com</a>
          </Error> : 
          null
        }
      </FormWrap>
      <Video autoPlay loop muted>
        <source src={loginVid} type='video/mp4'/>
      </Video>
      <CompanyLabel>SCENTROiD</CompanyLabel>
      <li>
        <a href='mailto:support@scentroid.com' alt='email_contact'>
          <Img src={mailIcon} alt='mail' width='1.5rem'/>
        </a>
        <a href='https://twitter.com/Scentroid' target="_blank" alt='twitter'>
          <Img src={twit} alt='twit' width='1.5rem'/>
        </a>
        <a 
          href='https://www.youtube.com/user/Scentroid' 
          target="_blank" 
          alt='Youtube'>
          <Img src={yt} alt='yt' width='1.5rem'/>
        </a>
        <a 
          href='https://www.instagram.com/scentroid' 
          target="_blank" 
          alt='Instagram'>
          <Img src={ig} alt='ig' width='1.5rem'/>
        </a>
        <a 
          href='http://linkedin.com/company/scentroid' 
          target="_blank" 
          alt='Linkedin'>
          <Img src={li} alt='li' width='1.5rem'/>
        </a>
        <a 
          href='https://www.facebook.com/scentroid/' 
          target="_blank" 
          alt='facebook'>
          <Img src={fb} alt='fb' width='1.5rem'/>
        </a>
      </li>
    </Page>
  );
}
