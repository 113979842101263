/* dateFormat formats the date for last sync notification and for individual
 * list items with short and full formats respectively. */
export const dateParser = (timestamp, fullFormat, seconds) => {
  let date = new Date(timestamp);
  const month = ('0' + (date.getMonth()+1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  let string = month + '.' + day  + '.' + date.getFullYear();

  if (fullFormat) {
    const hours = ('0' + date.getHours()).slice(-2);
    const mins = ('0' + date.getMinutes()).slice(-2);

    if (seconds) {
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return string + ' ' + hours + ':' + mins + ':' + seconds;
    }
    return string + ' ' + hours + ':' + mins;
  }

  return string;
}
